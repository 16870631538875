export default {
	computed: {
		gameLaunchMode() {
			return process.env.VUE_APP_GAME_LAUNCH_MODE;
		},
		myBalance() {
			var balance = null;
			if (
				process.env.VUE_APP_GAME_LAUNCH_MODE == 'single' &&
				this.$store.state.popups.length > 0
			) {
				balance = '0';
				// if (this.$store.state.gameType == 'slot') {
				// 	balance = '0';
				// } else if (this.$store.state.gameType == 'casino') {
				// 	balance = this.numberString(this.profile.balance);
				// }
			} else if (
				process.env.VUE_APP_GAME_LAUNCH_MODE == 'multiple' &&
				this.$store.state.popups.length > 0
			) {
				balance = '0';
				// if (this.$store.state.gameType == 'slot') {
				// 	balance = '0';
				// } else if (this.$store.state.gameType == 'casino') {
				// 	balance = this.numberString(this.profile.balance);
				// }
			} else {
				balance = this.numberString(this.profile.balance);
			}
			return balance;
		},
	},
	methods: {
		showModal(id, tab_id) {
			var vm = this

			var modal = document.getElementById(id)
			modal.classList.add("show");
			modal.addEventListener('click', function (e) {
				e = window.event || e;
				if (this === e.target) {
					vm.hideModal(id)
				}
			});

			if (tab_id) {
				vm.goToSlide(tab_id)
			}
		},
		hideModal(id) {
			let modal = document.getElementById(id)
			modal.classList.add("hide");
			setTimeout(() => {
				modal.classList.remove("show");
				modal.classList.remove("hide");
			}, 300)
		},
		initScrollables() {
			const scrollables = document.querySelectorAll('.scrollable');
			// console.log(scrollables)
			let isDown = false;
			let startX;
			let scrollLeft;
			scrollables.forEach(s => {
				s.addEventListener('mousedown', (e) => {
					isDown = true;
					s.classList.add('active');
					startX = e.pageX - s.offsetLeft;
					scrollLeft = s.scrollLeft;
				});
				s.addEventListener('mouseleave', () => {
					isDown = false;
					s.classList.remove('active');
				});
				s.addEventListener('mouseup', () => {
					isDown = false;
					s.classList.remove('active');
				});
				s.addEventListener('mousemove', (e) => {
					if (!isDown) return;
					e.preventDefault();
					const x = e.pageX - s.offsetLeft;
					const walk = (x - startX) * 3; //scroll-fast
					s.scrollLeft = scrollLeft - walk;
				});
			});

		},
		goToSlide(id) {
			var tabs = document.querySelectorAll('.tab')
			var forms = document.querySelectorAll('.form-wrapper')
			const target = document.getElementById(id);
			const target_tab = document.getElementById(`tab-${id}`);
			target.parentNode.scrollLeft = target.offsetLeft;
			tabs.forEach(tab => {
				tab.classList.remove('active')
			});
			forms.forEach(form => {
				form.classList.remove('active')
			});
			target.classList.add('active')
			target_tab.classList.add('active')
		},
		async launchGame(game) {
			const res = await this.$store.dispatch('game/launch', {
				game_id: game.id,
				vendor: game.vendor,
			});
			if (res.status == 200) {
				this.openGameWindow(res.data.data.url, game.id)
			} else if (res.status == 401) {
				this.$router.replace({ path: '/' }).catch(() => { });
			} else {
				this.$swal({
					icon: 'error',
					title: this.$t(res.data.message.toLowerCase()),
					confirmButtonText: this.$t('ok'),
					showConfirmButton: true,
					timer: 0
				});
			}
		},
		async launchGame2(game, product, type) {
			var pl
			if (type == 'casino') {
				pl = {
					product: game.product,
					language: this.$i18n.locale,
					game: game.game
				}
			}
			else {
				pl = {
					product: product,
					language: this.$i18n.locale,
					game: game.game_id.toString()
				}
			}
			const res = await this.$store.dispatch('game/launch2', pl);

			if (res.status == 200) {
				this.openGameWindow(res.data.data.url, game.product)
			} else if (res.status == 401) {
				this.$router.replace({ path: '/' }).catch(() => { });
			} else {
				this.$swal({
					icon: 'error',
					title: this.$t(res.data.message.toLowerCase()),
					confirmButtonText: this.$t('ok'),
					showConfirmButton: true,
					timer: 0
				});
			}
		},
		async launchHiltonGame() {
			const res = await this.$store.dispatch('game/launchHilton');
			if(res.data.returnCode == 1) {
				this.openGameWindow(res.data.message, 'hilton')
			} else {
				this.$swal({
					icon: 'error',
					title: this.$t(res.data.message.toLowerCase()),
					confirmButtonText: this.$t('ok'),
					showConfirmButton: true,
					timer: 0
				});
			}
		},
		async launchBotaGame() {
			const res = await this.$store.dispatch('game/launchBota');
			if(res.data.result_code == 0) {
				this.openGameWindow(res.data.result_value.link, 'bota')
			} else {
				this.$swal({
					icon: 'error',
					title: this.$t(res.data.message.toLowerCase()),
					confirmButtonText: this.$t('ok'),
					showConfirmButton: true,
					timer: 0
				});
			}
		},
		async openGameWindow($url, window_id) {
			var queryParams = {
				url: $url,
			};

			if(['hilton','bota'].includes(window_id)) {
				queryParams.vendor = window_id;
			}
			var queryString = Object.keys(queryParams)
				.map(
					(key) =>
						key + '=' + encodeURIComponent(queryParams[key])
				)
				.join('&');
			var window_name =
				this.gameLaunchMode === 'single'
					? 'game'
					: `game-${window_id}`;

			const isMobile = {
				Android: function () {
					return navigator.userAgent.match(/Android/i);
				},
				BlackBerry: function () {
					return navigator.userAgent.match(/BlackBerry/i);
				},
				iOS: function () {
					return navigator.userAgent.match(
						/iPhone|iPad|iPod/i
					);
				},
				Opera: function () {
					return navigator.userAgent.match(/Opera Mini/i);
				},
				Windows: function () {
					return (
						navigator.userAgent.match(/IEMobile/i) ||
						navigator.userAgent.match(/WPDesktop/i)
					);
				},
				any: function () {
					return (
						isMobile.Android() ||
						isMobile.BlackBerry() ||
						isMobile.iOS() ||
						isMobile.Opera() ||
						isMobile.Windows()
					);
				},
			};
			try {
				if (isMobile.any()) {
					// alert('this is a mobile')
					// var windowOpen =  window.open(`/game-window/casino?${queryString}`, '_blank');
					const url = `/game-window/casino?${queryString}`;
					const anchor = document.createElement('a');
					anchor.href = url;
					anchor.target = '_blank';
					anchor.rel = 'noopener noreferrer'; // Security measure to prevent tab-napping
					document.body.appendChild(anchor);
					anchor.click();
					document.body.removeChild(anchor);
				} else {
					// alert('this is not a mobile')
					var windowOpen = window.open(
						`/game-window/casino?${queryString}`,
						window_name,
						'width=' +
						screen.availWidth +
						',height=' +
						screen.availHeight +
						',fullscreen=yes, toolbar=no, location=no, directories=no, status=no, menubar=no,scrollbars=no,resizable=no'
					);
				}
			} catch (e) { }
		}

	},
}