import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import { i18n } from "./i18n.js"
import VueNumberFormat from 'vue-number-format'
import VueSweetalert2 from 'vue-sweetalert2'

import 'sweetalert2/dist/sweetalert2.min.css'
import '@/assets/css/main.css'
import '@/assets/css/monaco.css'
import '@/assets/css/button.css'
import '@/assets/css/helpers.css'
const options = {
    // toast: true,
    // position: "top-end",
    color: '#fff',
    background: '#293642',
    timer: 2000,
    timerProgressBar: true,
};
createApp(App)
    .use(store)
    .use(router)
    .use(i18n)
    .use(VueNumberFormat)
    .use(VueSweetalert2, options)
    .mount('#app')

