<template>
    <nav>
        <router-link
            to="/"
            class="logo"></router-link>
        <router-link
            to="/"
            class="nav-link">
            {{ $t('game list') }}
        </router-link>
        <router-link
            to="/transaction/deposit"
            class="nav-link disabled">
            {{ $t('request deposit') }}
        </router-link>
        <router-link
            to="/transaction/withdraw"
            class="nav-link disabled">
            {{ $t('request withdraw') }}
        </router-link>
        <router-link
            to="/transaction"
            class="nav-link">
            {{ $t('transaction history') }}
        </router-link>
        <router-link
            to="/bet"
            class="nav-link">
            {{ $t('bet history') }}
        </router-link>
        <router-link
            to="/coupon"
            class="nav-link">
            <span
                class="notif-badge"
                v-if="profile.coupon.unclaimed"
                >{{ profile.coupon.unclaimed }}</span
            >
            {{ $t('coupon') }}
        </router-link>
        <router-link
            to="/ticket"
            class="nav-link">
            <span
                class="notif-badge"
                v-if="profile.ticket.unread"
                >{{ profile.ticket.unread }}</span
            >
            {{ $t('customer service center') }}
        </router-link>
        <router-link
            to="/announcement"
            class="nav-link">
            <span
                class="notif-badge"
                v-if="profile.recent_announcement"
                >{{ profile.recent_announcement }}</span
            >
            {{ $t('announcement') }}
        </router-link>
        <!-- <div
            class="auth-links"
            v-if="!$store.getters['auth/authenticated']">
            <a
                href="javascript:void(0);"
                @click="showModal('auth-modal', 'login')"
                class="btn btn-outline"
                >{{ $t('login') }}</a
            >
            <a
                href="javascript:void(0);"
                @click="showModal('auth-modal', 'register')"
                class="btn btn-success"
                >{{ $t('register') }}</a
            >
        </div> -->
        <div
            class="profile-details"
            >
            <a
                href="javascript:void(0)"
                class="profile-details-link">
                <img
                    src="@/assets/img/svg-icons/wallet-icon.svg"
                    alt="wallet-icon" />
                <span class="text-end text-warning">{{ myBalance }}</span>

                <span
                    v-if="this.$store.state.popups.length > 0"
                    class="game-indicator"
                    >IN-GAME</span
                >
                <img
                    v-if="this.$store.state.launching_game"
                    src="@/assets/img/small-loader.svg"
                    alt="" />
            </a>
            <a
                href="javascript:void(0)"
                class="profile-details-link">
                <img
                    src="@/assets/img/svg-icons/point-icon.svg"
                    alt="point-icon" /><span class="text-end text-info">
                    {{ numberString(profile.points) }}</span
                >
                <div class="dropdown">
                    <a
                        href="javascript:void(0);"
                        @click="convertPoints"
                        >{{ $t('point money conversion') }}</a
                    >
                </div></a
            >

            <a
                href="javascript:void(0)"
                class="profile-details-link">
                <img
                    src="@/assets/img/svg-icons/user-icon.svg"
                    alt="user-icon" /><span>{{ profile.username }}</span></a
            >
            <a
                href="javascript:void(0);"
                @click="logout"
                class="btn btn-danger logout-lg">
                <span>{{ $t('logout') }}</span></a
            >
            <a
                href="javascript:void(0);"
                class="logout-sm"
                @click="logout">
                <img
                    style="width: 25px"
                    src="@/assets/img/svg-icons/switch-icon.svg"
                    alt="switch-icon"
            /></a>
        </div>
    </nav>
</template>

<script>
import ui from '@/mixins/ui';
import number from '@/mixins/number';
export default {
    name: 'TopNav',
    mixins: [ui, number],
    data() {
        return { waiting: false };
    },
    computed: {
        profile() {
            return this.$store.getters['auth/profile'];
        },
    },
    methods: {
        async logout() {
            const confirmed = await this.$swal({
                icon: 'question',
                title: this.$t('Do you want to logout?'),
                confirmButtonText: this.$t('ok'),
                cancelButtonText: this.$t('cancel'),
                showCancelButton: true,
                timer: 0,
            }).then((result) => {
                return result.isConfirmed;
            });
            console.log(confirmed);
            if (confirmed) {
                this.waiting = true;
                const success = await this.$store.dispatch('auth/logoutUser');
                this.waiting = false;
                if (success) {
                    this.$router.replace({ path: '/' });
                }
            }
        },
        async convertPoints() {
            const confirmed = await this.$swal({
                title: '포인트머니를 전환 하시겠습니까?',
                icon: 'question',
                showCancelButton: true,
                cancelButtonText: this.$t('cancel'),
                confirmButtonText: this.$t('ok'),
                timer: 0,
            }).then((result) => {
                return result.isConfirmed;
            });
            if (confirmed) {
                const res = await await this.$store.dispatch(
                    'auth/convertPoints'
                );
                this.$swal({
                    title: this.$t(res.data.message),
                    icon: res.data.status,
                    timer: res.success ? 2000 : null,
                    confirmButtonText: this.$t('ok'),
                });
                if (res.success) {
                    this.$store.dispatch('auth/getProfile');
                }
            }
        },
    },
};
</script>
