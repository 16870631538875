import { createRouter, createWebHistory } from 'vue-router'
import Home from '../views/Home.vue'
import store from "../store"
const routes = [
    {
        path: '/',
        name: 'home',
        component: Home
    },
    // {
    //     path: '/register',
    //     name: 'register',
    //     component: () => import(/* webpackChunkName: "register" */ '../views/Register.vue'),
    //     meta: { requiresVisitor: true }
    // },
    {
        path: '/game',
        name: 'game-list',
        component: () => import(/* webpackChunkName: "game-list" */ '../views/GameList.vue'),
        meta: { requiresAuth: true }
    },
    {
        path: '/game/:type(casino|slot)',
        name: 'provider-game-list',
        component: () => import(/* webpackChunkName: "provider-game-list" */ '../views/ProviderGameList.vue'),
        meta: { requiresAuth: true }
    },
    {
        path: "/game-window/:type(casino|slot)",
        name: "game-window",
        component: () => import(/* webpackChunkName: "game-window" */ '../views/GameWindow.vue'),
        meta: { requiresAuth: true },
    },
    {
        path: '/transaction',
        name: 'transaction',
        component: () => import(/* webpackChunkName: "transaction" */ '../views/Transaction.vue'),
        meta: { requiresAuth: true }
    },
    // {
    //     path: '/transaction/:type',
    //     name: 'transaction-create',
    //     component: () => import(/* webpackChunkName: "transaction-create" */ '../views/DepositWithdraw.vue'),
    //     meta: { requiresAuth: true }
    // },
    {
        path: '/ticket',
        name: 'ticket',
        component: () => import(/* webpackChunkName: "ticket" */ '../views/CustomerService.vue'),
        meta: { requiresAuth: true }
    },
    {
        path: '/ticket/create',
        name: 'ticket-create',
        component: () => import(/* webpackChunkName: "ticket-create" */ '../views/CreateTicket.vue'),
        meta: { requiresAuth: true }
    },
    {
        path: '/ticket/view/:id',
        name: 'ticket-view',
        component: () => import(/* webpackChunkName: "ticket-view" */ '../views/ViewTicket.vue'),
        meta: { requiresAuth: true }
    },
    {
        path: '/bet',
        name: 'bet',
        component: () => import(/* webpackChunkName: "bet" */ '../views/BetHistory.vue'),
        meta: { requiresAuth: true }
    },
    {
        path: '/coupon',
        name: 'coupon',
        component: () => import(/* webpackChunkName: "coupon" */ '../views/Coupon.vue'),
        meta: { requiresAuth: true }
    }, {
        path: '/announcement',
        name: 'announcement',
        component: () => import(/* webpackChunkName: "announcement" */ '../views/Announcement.vue'),
        meta: { requiresAuth: true }
    }, {
        path: '/announcement/view/:id',
        name: 'announcement-view',
        component: () => import(/* webpackChunkName: "announcement-view" */ '../views/ViewAnnouncement.vue'),
        meta: { requiresAuth: true }
    },
    { 
        path: '/:pathMatch(.*)*', 
        redirect: '/'
    },
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
})



router.beforeEach(async (routeTo, routeFrom, next) => {

    const isAuthenticated = store.getters["auth/authenticated"];
    // if (isAuthenticated) {
    //     await store.dispatch("auth/getProfile");
    // }

    if (routeTo.matched.some((record) => record.meta.requiresAuth)) {
        if (!isAuthenticated) {
            next("/");
        } else {
            next();
        }
    } else if (routeTo.matched.some((record) => record.meta.requiresVisitor)) {
        if (isAuthenticated) {
            next("/");
        } else {
            next();
        }
    } else {
        next();
    }

    window.scrollTo({ top: 0 });
});

export default router
