<template>
    <Layout>
        <!-- <form class="login" autocomplete="off" @submit.prevent="handleLogin" > -->
        <form class="login" autocomplete="off" @submit.prevent="handleLogin" v-if="!$store.getters['auth/authenticated']">
            <img class="logo" src="@/assets/img/logos/monaco-logo-v2.png" alt="">
            <div class="dropdown-locale">
                <label for="dd-checkbox" id="dd-label">Language</label>
                <input type="checkbox" id="dd-checkbox">
                <span class="caret"></span>
            </div>
            <input v-show="!waiting" type="text" id="username" placeholder="Username" autocomplete="off" v-model="formData.username">
            <span v-show="!waiting" class="form-error" style="margin: -30px 0 17px 0; text-align: start;" v-for="e in v$.formData.username.$errors" :key="e">{{ $t(e.$message) }}</span>
            <input v-show="!waiting" type="password" id="password" placeholder="Password" autocomplete="new-password" v-model="formData.password">
            <span  v-show="!waiting" class="form-error" style="margin: -30px 0 17px 0; text-align: start;" v-for="e in v$.formData.password.$errors" :key="e">{{ $t(e.$message) }}</span>
            <input v-show="!waiting" type="submit" value="login" >
               <img v-show="waiting"
                class="login-loader"
                src="@/assets/img/small-loader.svg"
                alt="" />
            <!-- <div class="auth-control">Still not a member? <router-link to="/register">Register Here</router-link></div> -->
        </form>
        
        <GameGrid2 v-if="$store.getters['auth/authenticated']"/>
    </Layout>
</template>
<script>
import GameGrid2 from '@/components/GameGrid2.vue';
import Layout from '@/components/Layout.vue';
import ui from '@/mixins/ui';
import {
    required,
    minLength,
    maxLength,
    sameAs,
    helpers,
} from '@vuelidate/validators';
import { useVuelidate } from '@vuelidate/core';
export default {
    name: 'HomeView',
    mixins: [ui],
    components: {
        Layout,
        GameGrid2
    },
    data() {
        return {
            waiting: false,
            formData: {
                username: null,
                name: null,
            },
            formData2: {
                username: null,
                name: null,
                password: null,
                confirm_password: null,
                pin: null,
                referral_code: null,
            },
        };
    },
    setup() {
        return {
            v$: useVuelidate(),
        };
    },
    validations() {
        return {
            formData: {
                username: {
                    required: helpers.withMessage('required', required),
                },
                password: {
                    required: helpers.withMessage('required', required),
                },
            },
            formData2: {
                username: {
                    required: helpers.withMessage('required', required),
                    minLength: helpers.withMessage(
                        'should be atleast 2 characters',
                        minLength(2)
                    ),
                },
                name: {
                    required: helpers.withMessage('required', required),
                    minLength: helpers.withMessage(
                        'should be atleast 2 characters',
                        minLength(2)
                    ),
                    maxLength: helpers.withMessage(
                        'maximum length is 32 characters',
                        maxLength(32)
                    ),
                },
                password: {
                    required: helpers.withMessage('required', required),
                    minLength: helpers.withMessage(
                        'should be atleast 4 characters',
                        minLength(4)
                    ),
                    maxLength: helpers.withMessage(
                        'maximum length is 32 characters',
                        maxLength(32)
                    ),
                },
                pin: {
                    required: helpers.withMessage('required', required),
                    minLength: helpers.withMessage(
                        'should be atleast 4 characters',
                        minLength(4)
                    ),
                    maxLength: helpers.withMessage(
                        'maximum length is 4 characters',
                        maxLength(4)
                    ),
                },
                confirm_password: {
                    sameAs: helpers.withMessage(
                        `password doesn't match`,
                        sameAs(this.formData2.password)
                    ),
                },
            },
        };
    },
    methods: {
        async handleLogin() {
            if (this.waiting) {
                return;
            }
            this.v$.formData.$touch();
            if (!this.v$.formData.$invalid) {
                var fd = new FormData();
                fd.append('username', this.formData.username);
                fd.append('password', this.formData.password);

                this.waiting = true;
                const res = await this.$store.dispatch('auth/loginUser', fd);
               
                if (res.success) {
                    this.$store.dispatch('auth/getProfile');
                    // this.hideModal('auth-modal');
                    this.reset();
                    this.$store.commit('triggerAnything');
                    // this.launchBotaGame();
                    // const res = await this.$store.dispatch('game/launchBota');
                    // if(res.data.result_code == 0) {
                    //     // await this.$store.dispatch('auth/logoutUser');
                    //     window.location.href = res.data.result_value.link
                    // } else {
                    //     this.$swal({
                    //         icon: 'error',
                    //         title: this.$t(res.data.message.toLowerCase()),
                    //         confirmButtonText: this.$t('ok'),
                    //         showConfirmButton: true,
                    //         timer: 0
                    //     });
                    // }
                } else {
                     this.$swal({
                        icon: res.data.status,
                        title: this.$t(res.data.message),
                        confirmButtonText: this.$t('ok'),
                    });
                }
                this.waiting = false;
            }
        },
        async handleRegister() {
            if (this.waiting) {
                return;
            }
            this.v$.formData2.$touch();
            if (!this.v$.formData2.$invalid) {
                var fd = new FormData();
                fd.append('username', this.formData2.username);
                fd.append('name', this.formData2.name);
                fd.append('password', this.formData2.password);
                fd.append('confirm_password', this.formData2.confirm_password);
                fd.append('pin', this.formData2.pin);
                if (this.formData2.referral_code) {
                    fd.append('referral_code', this.formData2.referral_code);
                }

                this.waiting = true;
                const res = await this.$store.dispatch('auth/registerUser', fd);
                this.waiting = false;
                const vm = this;
                this.$swal({
                    icon: res.success ? 'success' : 'error',
                    title: this.$t(res.data.message),
                    confirmButtonText: this.$t('ok'),
                })

                if (res.success) {
                    this.reset();
                }
            }
        },
        reset() {
            this.v$.formData.$reset();
            this.formData.username = null;
            this.formData.password = null;

            this.v$.formData2.$reset();
            this.formData2.username = null;
            this.formData2.name = null;
            this.formData2.password = null;
            this.formData2.confirm_password = null;
            this.formData2.pin = null;
            this.formData2.referral_code = null;
        },
        validateNumber(evt) {
			let keyCode = (evt.keyCode ? evt.keyCode : evt.which);
			if ((keyCode < 48 || keyCode > 57) && keyCode !== 46) { // 46 is dot
				evt.preventDefault();
			}
		},
    },
    mounted() {
        // this.initScrollables();
    },
};
</script>
