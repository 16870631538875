<template>
    <TopNav  v-if="$store.getters['auth/authenticated']"/>
    <main :class="{'logged-in':$store.getters['auth/authenticated']}">
        <slot />
        <!-- <FooterOriginal /> -->
        <BottomNav v-if="$store.getters['auth/authenticated']"/>  
    </main>
    <!-- <AuthModal /> -->
    <FloatingButton />
</template>

<script>
import TopNav from '@/components/TopNav.vue';
import FooterOriginal from '@/components/FooterOriginal.vue';
import BottomNav from '@/components/BottomNav.vue';
import AuthModal from '@/components/AuthModal.vue';
import FloatingButton from '@/components/FloatingButton.vue';
import ScreenLoader from '@/components/ScreenLoader.vue';
export default {
    name: 'Layout',
    components: {
        TopNav,
        FooterOriginal,
        BottomNav,
        AuthModal,
        FloatingButton,
        ScreenLoader,
    },
};
</script>
