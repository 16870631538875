import $api from "../api";
import axios from "axios";


export const state = {
    casino1: [
        {
            "title": "EVOLUTION",
            "type": "lobby",
            "id": "evolution_game_shows",
            "provider": "QX",
            "name": "에볼루션",
            "logo": "evolution-gaming-icon.png",
            "game_thumbnail": "evolution.png",
            "thumbnail": "https://media.ttfileserver.com/359/evolution/300x300/evolution_game_shows.png",
            "thumbnails": {
                "300x300": "https://media.ttfileserver.com/359/evolution/300x300/evolution_game_shows.png"
            },
            "vendor": "evolution"
        },
        {
            "title": "Dreamgame Lobby",
            "type": "lobby",
            "id": "dgcasino",
            "provider": "CX",
            "name": "드림게이밍",
            "logo": "dream-gaming-icon.png",
            "game_thumbnail": "dreamgaming.png",
            "thumbnail": "https://ap-south-1.linodeobjects.com/cdn.honorlink.org/dreamgame/lobby.jpg",
            "thumbnails": {
                "300x300": "https://ap-south-1.linodeobjects.com/cdn.honorlink.org/dreamgame/lobby.jpg"
            },
            "vendor": "DreamGame"
        },
        {
            "title": "Pragmatic Play",
            "type": "lobby",
            "id": "101",
            "provider": "CX",
            "name": "프라그매틱 라이브",
            "logo": "pragmatic-play-icon.png",
            "game_thumbnail": "pragmaticplay.png",
            "thumbnail": "https://ap-south-1.linodeobjects.com/cdn.honorlink.org/pragmaticplay/live-lobby.jpg",
            "thumbnails": {
                "300x300": "https://ap-south-1.linodeobjects.com/cdn.honorlink.org/pragmaticplay/live-lobby.jpg"
            },
            "vendor": "PragmaticPlay Live"
        },

        {
            "title": "WM LIVE",
            "type": "lobby",
            "id": "wmcasino",
            "provider": "",
            "name": "WM 카지노",
            "logo": "wmcasino-icon.png",
            "game_thumbnail": "wmcasino.png",
            "thumbnail": "https://ap-south-1.linodeobjects.com/cdn.honorlink.org/wm_live/live-lobby.jpg",
            "thumbnails": {
                "300x300": "https://ap-south-1.linodeobjects.com/cdn.honorlink.org/wm_live/live-lobby.jpg"
            },
            "vendor": "WM Live"
        },
        {
            "title": "Asia Gaming",
            "type": "lobby",
            "id": "0",
            "provider": "CX",
            "name": "아시아게이밍",
            "logo": "asia-gaming-icon.png",
            "game_thumbnail": "asiagaming.png",
            "thumbnail": "https://ap-south-1.linodeobjects.com/cdn.honorlink.org/asiagaming/lobby.png",
            "thumbnails": {
                "300x300": "https://ap-south-1.linodeobjects.com/cdn.honorlink.org/asiagaming/lobby.png"
            },
            "vendor": "Asia Gaming"
        },

        {
            "title": "TG/PD",
            "type": "lobby",
            "id": "bota",
            "provider": "",
            "name": "TG SPEED",
            "logo": "bota-icon.png",
            "game_thumbnail": "tgspeed.png",
            "thumbnail": "https://ap-south-1.linodeobjects.com/cdn.honorlink.org/tgspeed/lobby.png",
            "thumbnails": {
                "300x300": "https://ap-south-1.linodeobjects.com/cdn.honorlink.org/tgspeed/lobby.png"
            },
            "vendor": "bota"
        },

        {
            "title": "BigGames",
            "type": "lobby",
            "id": "betgame_casino",
            "provider": "",
            "name": "빅게이밍",
            "logo": "betgame-icon.png",
            "game_thumbnail": "betgames.png",
            "thumbnail": "https://ap-south-1.linodeobjects.com/cdn.honorlink.org/taishan/lobby.png",
            "thumbnails": {
                "300x300": "https://ap-south-1.linodeobjects.com/cdn.honorlink.org/taishan/lobby.png"
            },
            "vendor": "Betgames.tv"
        },
        {
            "title": "Skywind Live",
            "type": "lobby",
            "id": "skywind_casino",
            "name": "스카이윈드",
            "logo": "skywind-icon.png",
            "game_thumbnail": "skywind.png",
            "thumbnail": "https://goodluckmate.com/storage/attribute_value/5ea7ef5bebdd55754abdb3b19b4ec906/c/skywind-250x250.jpg",
            "thumbnails": {
                "300x300": "https://goodluckmate.com/storage/attribute_value/5ea7ef5bebdd55754abdb3b19b4ec906/c/skywind-250x250.jpg"
            },
            "vendor": "Skywind Live"
        }
    ],
    slot1: [
        {
            "title": "EVOPLAY",
            "type": "lobby",
            "id": "evoplay_slots",
            "logo": "evo-icon.png",
            "name": "에보플레이",
            "game_thumbnail": "evoplay.png",
            "thumbnails": {
                "300x300": "https://ap-south-1.linodeobjects.com/cdn.honorlink.org/playson/lobby.png"
            },
            "vendor": "EVOPLAY"
        },
        {
            "title": "CQ9",
            "type": "lobby",
            "id": "cq9_slots",
            "name": "씨큐",
            "logo": "cq9-icon.png",
            "game_thumbnail": "cq9.png",
            "thumbnail": "https://ap-south-1.linodeobjects.com/cdn.honorlink.org/cq9/lobby.png",
            "thumbnails": {
                "300x300": "https://ap-south-1.linodeobjects.com/cdn.honorlink.org/cq9/lobby.png"
            },
            "vendor": "CQ9"
        },
        {
            "title": "PRAGMATIC",
            "type": "lobby",
            "id": "pragmatic_play_slots",
            "name": "프라그매틱 플레이",
            "logo": "pragmatic-play-icon.png",
            "game_thumbnail": "pragmaticplay.png",
            "thumbnail": "https://ap-south-1.linodeobjects.com/cdn.honorlink.org/pragmaticplay/lobby.jpg",
            "thumbnails": {
                "300x300": "https://ap-south-1.linodeobjects.com/cdn.honorlink.org/pragmaticplay/lobby.jpg"
            },
            "vendor": "PragmaticPlay"
        },
        {
            "title": "Wazdan",
            "type": "lobby",
            "id": "wazdan_slots",
            "name": "와즈단",
            "logo": "wazdan-icon.png",
            "game_thumbnail": "wazdan.png",
            "thumbnail": "https://igamingbusiness.com/wp-content/uploads/2020/08/Wazdan-logo-with-tagline_0.jpg",
            "thumbnails": {
                "300x300": "https://igamingbusiness.com/wp-content/uploads/2020/08/Wazdan-logo-with-tagline_0.jpg"
            },
            "vendor": "Wazdan"
        },
        {
            "title": "NETENT",
            "type": "lobby",
            "id": "netent_slots",
            "name": "넷앤트",
            "logo": "netent-icon.png",
            "game_thumbnail": "netent.png",
            "thumbnail": "https://ap-south-1.linodeobjects.com/cdn.honorlink.org/netent/lobby.png",
            "thumbnails": {
                "300x300": "https://ap-south-1.linodeobjects.com/cdn.honorlink.org/netent/lobby.png"
            },
            "vendor": "netent"
        },
        {
            "title": "Red Tiger",
            "type": "lobby",
            "id": "redtiger_slots",
            "name": "레드타이거",
            "logo": "redtiger-icon.png",
            "game_thumbnail": "redtiger.png",
            "thumbnail": "https://ap-south-1.linodeobjects.com/cdn.honorlink.org/redtiger/lobby.png",
            "thumbnails": {
                "300x300": "https://ap-south-1.linodeobjects.com/cdn.honorlink.org/redtiger/lobby.png"
            },
            "vendor": "redtiger"
        },
        {
            "title": "Playson",
            "type": "lobby",
            "id": "playson_slots",
            "name": "플레이슨",
            "logo": "playson-icon.png",
            "game_thumbnail": "playson.png",
            "thumbnail": "https://ap-south-1.linodeobjects.com/cdn.honorlink.org/playson/lobby.png",
            "thumbnails": {
                "300x300": "https://ap-south-1.linodeobjects.com/cdn.honorlink.org/playson/lobby.png"
            },
            "vendor": "Playson"
        },
        {
            "title": "PlayStar",
            "type": "lobby",
            "id": "playstar_slots",
            "name": "플레이스타",
            "logo": "play-star-icon.png",
            "game_thumbnail": "playstar.png",
            "thumbnail": "https://betandslots.com/wp-content/uploads/2020/05/playstar.jpg",
            "thumbnails": {
                "300x300": "https://betandslots.com/wp-content/uploads/2020/05/playstar.jpg"
            },
            "vendor": "PlayStar"
        },
        {
            "title": "HABANERO",
            "type": "lobby",
            "id": "habanero_slots",
            "name": "하바네로",
            "logo": "habanero-icon.png",
            "game_thumbnail": "habanero.png",
            "thumbnail": "https://casinodaddy.com/wp-content/uploads/2020/04/habanero-logo.png",
            "thumbnails": {
                "300x300": "https://casinodaddy.com/wp-content/uploads/2020/04/habanero-logo.png"
            },
            "vendor": "Habanero"
        },
        {
            "title": "GameArt",
            "type": "lobby",
            "id": "gameart_slots",
            "name": "게임아트",
            "logo": "gameart-icon.png",
            "game_thumbnail": "gameart.png",
            "vendor": "GameArt"
        },
        {
            "title": "Blueprint Gaming",
            "type": "lobby",
            "id": "blueprint_slots",
            "name": "블루프린트게이밍",
            "logo": "blueprint-icon.png",
            "game_thumbnail": "blueprint.png",
            "vendor": "Blueprint Gaming"
        },
        {
            "title": "Thunderkick",
            "type": "lobby",
            "id": "thunderkick_slots",
            "logo": "thunderkick-icon.png",
            "name": "썬더킥",
            "game_thumbnail": "thunderkick.png",
            "thumbnail": "https://www.thunderkick.com/wp-content/uploads/2021/04/LOGO_horizontal_black-768x768.jpg",
            "thumbnails": {
                "300x300": "https://www.thunderkick.com/wp-content/uploads/2021/04/LOGO_horizontal_black-768x768.jpg"
            },
            "vendor": "Thunderkick"
        },
        {
            "title": "Nolimit City",
            "type": "lobby",
            "id": "nolimitcity_slots",
            "name": "노리밋시티",
            "logo": "no-limit-city-icon.png",
            "game_thumbnail": "nolimit.png",
            "thumbnail": "https://ap-south-1.linodeobjects.com/cdn.honorlink.org/nolimitcity/lobby.png",
            "thumbnails": {
                "300x300": "https://ap-south-1.linodeobjects.com/cdn.honorlink.org/nolimitcity/lobby.png"
            },
            "vendor": "Nolimit City"
        },
        {
            "title": "Mobilots",
            "type": "lobby",
            "id": "mobilots_slots",
            "logo": "mobilots-icon.png",
            "name": "모빌리엇츠",
            "game_thumbnail": "mobilots.png",
            "thumbnail": "https://assets.vegasslotsonline.com/vegasslotsonline.com/assets/images/software/mobilots.png",
            "thumbnails": {
                "300x300": "https://assets.vegasslotsonline.com/vegasslotsonline.com/assets/images/software/mobilots.png"
            },
            "vendor": "Mobilots"
        },
        {
            "title": "Play pearls",
            "type": "lobby",
            "id": "pearls_slots",
            "name": "플레이펄즈",
            "logo": "play-pearls-icon.png",
            "game_thumbnail": "playpearls.png",
            "thumbnail": "https://www.top10onlinecasino.site/wp-content/uploads/2019/06/PlayPearls.png",
            "thumbnails": {
                "300x300": "https://www.top10onlinecasino.site/wp-content/uploads/2019/06/PlayPearls.png"
            },
            "vendor": "PlayPearls"
        },

        {
            "title": "Dragoon Soft",
            "type": "lobby",
            "id": "dragoon_slots",
            "name": "드라군소프트",
            "logo": "dragon-soft-icon.png",
            "game_thumbnail": "dragoonsoft.png",
            "thumbnail": "https://dragoonsoftslot.com/wp-content/uploads/2022/04/dragoon-soft-slot-%E0%B9%80%E0%B8%A7%E0%B9%87%E0%B8%9A%E0%B8%95%E0%B8%A3%E0%B8%87_03.png",
            "thumbnails": {
                "300x300": "https://dragoonsoftslot.com/wp-content/uploads/2022/04/dragoon-soft-slot-%E0%B9%80%E0%B8%A7%E0%B9%87%E0%B8%9A%E0%B8%95%E0%B8%A3%E0%B8%87_03.png"
            },
            "vendor": "Dragoon Soft"
        }
    ],
    casino2: [
        {
            "product": "1",
            "game": "0",
            "language": "ko",
            "name": "에볼루션",
            "game_thumbnail": "evolution.jpg",
            "vendor": "evolution"
        },
        {
            "product": "5",
            "game": "0",
            "language": "ko",
            "name": "아시아게이밍",
            "game_thumbnail": "asiagaming.jpg",
            "vendor": "asiagaming"
        },
        {
            "product": "6",
            "game": "0",
            "language": "ko",
            "name": "드림게이밍",
            "game_thumbnail": "dreamgaming.jpg",
            "vendor": "dreamgaming"

        },
        {
            "product": "10",
            "game": "0",
            "language": "ko",
            "name": "프라그매틱플레이",
            "game_thumbnail": "pragmaticplay.jpg",
            "vendor": "pragmaticplay"
        }
    ],
    slot2: [
        {
            "prd_id": 200,
            "prd_name": "프라그마틱 슬롯",
            "game_thumbnail": "slot-button-1065.jpg"
        },
        {
            "prd_id": 201,
            "prd_name": "하바네로",
            "game_thumbnail": "slot-button-1066.jpg"
        },
        {
            "prd_id": 202,
            "prd_name": "엘리시움",
            "game_thumbnail": "elysium.png"
        },
        {
            "prd_id": 204,
            "prd_name": "퀵스핀",
            "game_thumbnail": "slot-button-1124.jpg"
        },
        {
            "prd_id": 205,
            "prd_name": "스페이드 게이밍",
            "game_thumbnail": "spade-gaming.jpg"
        },
        {
            "prd_id": 207,
            "prd_name": "플레이 앤 고",
            "game_thumbnail": "playngo.png"
        },
        {
            "prd_id": 208,
            "prd_name": "월드 매치",
            "game_thumbnail": "WorldMatch.jpg"
        },
        {
            "prd_id": 209,
            "prd_name": "마이크로 게이밍 슬롯",
            "game_thumbnail": "micro.png"
        },
        {
            "prd_id": 212,
            "prd_name": "YL게이밍",
            "game_thumbnail": "ylgaming.jpg"

        },
        {
            "prd_id": 214,
            "prd_name": "Evo 넷엔트",
            "game_thumbnail": "netent.jpg"
        },
        {
            "prd_id": 213,
            "prd_name": "Evo 레드타이거",
            "game_thumbnail": "slot-button-1149.jpg"
        },
        {
            "prd_id": 215,
            "prd_name": "드라군 소프트",
            "game_thumbnail": "dragoon-soft.jpg"
        },
        {
            "prd_id": 216,
            "prd_name": "YGG 드라실",
            "game_thumbnail": "yggdrasil.jpg"
        },
        {
            "prd_id": 217,
            "prd_name": "분고",
            "game_thumbnail": "booongo.png"
        },
        {
            "prd_id": 218,
            "prd_name": "플레이슨",
            "game_thumbnail": "playson.png"

        },
        {
            "prd_id": 219,
            "prd_name": "플레이테크 슬롯",
            "game_thumbnail": "playtech.png"
        },
        {
            "prd_id": 220,
            "prd_name": "CQ9 슬롯",
            "game_thumbnail": "CQ9.jpg"
        },
        {
            "prd_id": 206,
            "prd_name": "올 웨이 스핀",
            "game_thumbnail": "all_way_spin.jpg"

        },
        {
            "prd_id": 222,
            "prd_name": "와즈단",
            "game_thumbnail": "wazdan.png"
        },
        {
            "prd_id": 223,
            "prd_name": "포켓 게임즈 소프트",
            "game_thumbnail": "pgsoft.png"
        },
        {
            "prd_id": 221,
            "prd_name": "스카이윈드 슬롯",
            "game_thumbnail": "skywind.png"
        },
        {
            "prd_id": 224,
            "prd_name": "로얄 슬롯 게이밍",
            "game_thumbnail": "royal-slot-gaming.jpg"
        },
        {
            "prd_id": 225,
            "prd_name": "Evo 빅 타임 게이밍",
            "game_thumbnail": "evo-big-time.jpg"
        },
        {
            "prd_id": 227,
            "prd_name": "Evo 에볼 노 리미트",
            "game_thumbnail": "no-limit-city.jpg"
        },
        {
            "prd_id": 228,
            "prd_name": "CC88",
            "game_thumbnail": "cc88.png"
        },
        {
            "prd_id": 230,
            "prd_name": "패스트 스핀",
            "game_thumbnail": "fast-spin.jpg"
        },
        {
            "prd_id": 231,
            "prd_name": "넥스트 스핀",
            "game_thumbnail": "next-spin.jpg"
        },
        {
            "prd_id": 249,
            "prd_name": "나가게임즈",
            "game_thumbnail": "naga-games.png"
        },
        {
            "prd_id": 250,
            "prd_name": "히든버튼",
            "game_thumbnail": "hidden-button.png"
        }
    ]
}
export const actions = {
    getList({ commit, rootGetters, dispatch }, filename) {
        return new Promise(function (resolve) {
            axios
                .get(`/data/${filename}.json`, {
                    headers: {
                        Authorization: rootGetters["auth/bearer_token"],
                    },
                })
                .then(function (res) {
                    if (res.status == 200) {
                        // commit("setData", res);
                        resolve(res);
                    }
                })
                .catch(function (err) {
                    console.log(err, "err");
                    if (err.response.status == 401) {
                        dispatch("auth/logout", {}, { root: true }).then(() => { });
                    }
                });
        });
    },
    gameList({ commit, rootGetters, dispatch }, pl) {
        return new Promise(function (resolve) {
            axios
                .get(`/data/updated-${pl.type}-gamelist/${pl.game_id}.json`, {
                    headers: {
                        Authorization: rootGetters["auth/bearer_token"],
                    },
                })
                .then(function (res) {
                    if (res.status == 200) {
                        resolve(res);
                    }
                })
                .catch(function (err) {
                    console.log(err, "err");
                    if (err.response.status == 401) {
                        dispatch("auth/logout", {}, { root: true }).then(() => { });
                    }
                });
        });
    },
    gameList2({ commit, rootGetters, dispatch }, pl) {
        return new Promise(function (resolve) {
            axios
                .get(`/data/slot2-game-list.json`, {
                    headers: {
                        Authorization: rootGetters["auth/bearer_token"],
                    },
                })
                .then(function (res) {
                    if (res.status == 200) {
                        resolve(res);
                    }
                })
                .catch(function (err) {
                    console.log(err, "err");
                    if (err.response.status == 401) {
                        dispatch("auth/logout", {}, { root: true }).then(() => { });
                    }
                });
        });
    },
    launch({ commit, rootGetters, dispatch }, pl) {
        return new Promise(function (resolve) {
            commit('triggerLaunching', true, { root: true })
            $api
                .get(`player/game-launch?game_id=${pl.game_id}&vendor=${pl.vendor}`, {
                    headers: {
                        Authorization: rootGetters["auth/bearer_token"],
                    },
                })
                .then(function (res) {
                    if (res.status == 200) {
                        // console.log("gamelaunch", res);
                        // commit("setGameLaunch", res.data.data);
                        resolve(res);
                        commit('triggerLaunching', false, { root: true })
                    }
                })
                .catch(function (err) {
                    // console.log(err, "err");
                    if (err.response.status == 401) {
                        resolve(err.response);
                        //   dispatch("auth/logout", {}, { root: true }).then(() => {});

                    }
                    if (err.response.status == 422) {
                        console.log(err);
                        resolve(err.response);
                    }
                    commit('triggerLaunching', false, { root: true })
                });
        });
    },
    launch2({ commit, rootGetters, dispatch }, pl) {
        return new Promise(function (resolve) {
            commit('triggerLaunching', true, { root: true })
            $api
                .post(`player/kplay/auth`, pl, {
                    headers: {
                        Authorization: rootGetters["auth/bearer_token"],
                    },
                })
                .then(function (res) {
                    if (res.status == 200) {
                        resolve(res);
                        commit('triggerLaunching', false, { root: true })
                    }
                })
                .catch(function (err) {
                    resolve(err.response);
                    commit('triggerLaunching', false, { root: true })
                });
        });
    },
    launchHilton({ commit, rootGetters, dispatch }) {
        return new Promise(function (resolve) {
            commit('triggerLaunching', true, { root: true })
            $api
                .get(`hiltoncasino/game-launch`, {
                    headers: {
                        Authorization: rootGetters["auth/bearer_token"],
                    },
                })
                .then(function (res) {
                    if (res.status == 200) {
                        resolve(res);
                        commit('triggerLaunching', false, { root: true })
                    }
                })
                .catch(function (err) {
                    if (err.response.status == 401) {
                        resolve(err.response);
                    }
                    if (err.response.status == 422) {
                        console.log(err);
                        resolve(err.response);
                    }
                    commit('triggerLaunching', false, { root: true })
                });
        });
    },
    launchBota({ commit, rootGetters, dispatch }) {
        return new Promise(function (resolve) {
            commit('triggerLaunching', true, { root: true })
            $api
                .get(`/tigerapi/game-launch`, {
                    headers: {
                        Authorization: rootGetters["auth/bearer_token"],
                    },
                })
                .then(function (res) {
                    if (res.status == 200) {
                        resolve(res);
                        commit('triggerLaunching', false, { root: true })
                    }
                })
                .catch(function (err) {
                    if (err.response.status >= 400 && err.response.status < 500)  {
                        resolve(err.response);
                    }
                    commit('triggerLaunching', false, { root: true })
                });
        });
    },
}
